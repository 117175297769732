<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Student Payments</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">Students Payments</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">
              <h4 class="card-title">Payment</h4>
            </h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody v-if="userrequest.data">
                  <tr
                    v-for="(value, item) in userrequest.data.items"
                    :key="item"
                  >
                    <td>{{ item }}</td>
                    <td>{{ value }}</td>
                  </tr>
                  <tr>
                    <td style="text-align: left" colspan="2">
                      <p>
                        <strong>You are about to pay</strong>
                        {{ userrequest.total_amount }} EGP.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="userrequest.status == 'PAID'">
              <p
                style="
                  padding: 10px;
                  background: #259725;
                  color: white;
                  border-radius: 5px;
                "
              >
                Your request has been successfully processed.
              </p>
            </div>
            <div v-else class="form-group">
              <on-line-payment
                v-if="userrequest.data"
                :userrequest="userrequest"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OnLinePayment from "@/components/OnLinePayment.vue";
import useStudentPayment from "@/composables/student_payments";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  onBeforeMount,
  inject,
  ref,
  onMounted,
  onDeactivated,
  onUnmounted,
} from "vue";

export default {
  props: {
    userrequest_id: {
      required: true,
      type: Number,
    },
  },
  components: {
    OnLinePayment,
  },
  setup(props) {
    const swal = inject("$swal");
    const { getUserRequest, checkBankMisrPaymentStatus } = useStudentPayment();
    const hasAnyRole = inject("hasAnyRole");
    const router = useRouter();
    const store = useStore();
    const userrequest = ref([]);
    const disableBtn = ref(false);

    userrequest.value = store.getters.getUserPaymentRequest;

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasAnyRole("Parent,Student")) {
        router.push({ name: "401" });
      } else {
        if (
          store.getters.getUserPaymentRequest == null ||
          store.getters.getUserPaymentRequest.id != props.userrequest_id
        ) {
          userrequest.value = await getUserRequest(props.userrequest_id);
          store.dispatch("setUserPaymentRequest", userrequest.value);
        }
      }
    });

    onMounted(() => {
      // Add an event listener
      document.addEventListener("completePaymentCallback", async function () {
        await checkBankMisrPaymentStatus(
          userrequest.value.merchant_ref_num
        ).then((resp) => {
          if (resp.success == true) {
            swal
              .fire({
                icon: "success",
                title: "Completed...",
                text: resp.data,
              })
              .then(() => {
                router.push({ name: "Dashboard-Home" });
              });
          } else {
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: resp.message,
            });
          }
          disableBtn.value = false;
        });
      });
    });

    onDeactivated(() => {
      document.removeEventListener("completePaymentCallback", function () {
      });
    });

    onUnmounted(() => {
      document.removeEventListener("completePaymentCallback", function () {
      });
    });

    return {
      disableBtn,
      userrequest,
    };
  },
};
</script>

<style>
</style>

